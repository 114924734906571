////////////////////////////////////////////
// Fonctions reliées à la carte
////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { HTMLMarker, initMarker } from './mapMarker.js'
import { mapStyle } from './mapStyle.js' // récupère le style de la carte
import { mapOverlay } from './mapOverlay.js' // récupère l’objet de gestion des panels
import { mapUrl } from './mapUrl'

const loaderId = '#googleMapLoading'
const containerId = '#mapLayer'

let loader = null          // Loader jquery element
let container = null       // Map container jquery element
let map = null             // Google map reference

/**
 * Lazy loading of Google Map. Possibly called multiple times in 1 page
 */
export function googleMap() {

  if (!map) {

    initMap()

    // Wait for gmap to be loaded AND displayed before adding the first markers
    google.maps.event.addListenerOnce(map, 'tilesloaded', event => {
      refreshMarkers(map)
    })

  } else {

    // Wait for fade animation to end and reset bounds
    refreshMarkers(map)
  }
}

/**
 * Initialize all google map components
 */
function initMap() {

  const mapOption = {
    zoom: 14,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE
      ]
    },
    gestureHandling: 'cooperative',
    center: new google.maps.LatLng(46.016666, -73.449997),
    styles: mapStyle,
  }

  loader = $(loaderId)
  container = $(containerId)
  map = new google.maps.Map(document.getElementById(container.attr('id')), mapOption)

  // initialisation des marqueurs suite au chagement de Google
  mapUrl.onLoadInit()
  initMarker()
  mapOverlay.init()
}

export function resetMap() {

  map = null
  initMap()

  // Wait for gmap to be loaded AND displayed before adding the first markers
  google.maps.event.addListenerOnce(map, 'tilesloaded', event => {
    refreshMarkers(map)
  })
}

/**
 * De-reference all google map components. Called on page change
 */
export function destroyMap() {

  // Reset UI
  let event = new CustomEvent('panel.hide')
  window.dispatchEvent(event)

  map = null
}

/**
 * Initialize an ajax request to update the markers
 * Ignore if the map hasn't been initialized yet (since it's lazy loaded)
 */
export function refreshMarkers() {

  if (!map) return
  showLoader()

  let APIUrl = '/api/v1/immeubles'

  if (document.querySelectorAll('[data-barba-namespace="favoritesView"]').length > 0){
    APIUrl = '/api/v1/immeubles/favorites'
  }

  $.ajax({
    method: 'GET',
    url: APIUrl,
  }).done(resp => {
    if (resp.status === 'success') {
      updateMarkers(resp.data)
      HTMLMarker.fitBounds()
      hideLoader()
    }
  })
}

/**
 * Ajoute les marqueurs en overlay
 */
function updateMarkers(data) {

  var immeubles = data.immeubles

  immeubles.map(immeuble => {
    new HTMLMarker(map, immeuble.position.lat, immeuble.position.lng, immeuble.id, immeuble.label)
  })
}

function showLoader() {
  loader.stop().fadeIn()
}

function hideLoader() {
  loader.stop().fadeOut()
}

export function boundMap() {

  let bounds = new google.maps.LatLngBounds()
  map.fitBounds(bounds, 1)
  let zoom = map.getZoom()
  map.setZoom(zoom)
}

window.resetMap = resetMap
