/*
  Cette classe est utilisé pour gérer et créer les URLs de la carte.
 */

export class mapUrl {


  // GETTER / SETTER // SubLocalisation
  static setIDLocalisation(id) {
    mapUrl._idLocalisation = id
    mapUrl.updateUrl()
  }

  static getIDLocalisation() {
    return mapUrl._idLocalisation
  }

  static getOnLoadIDLocalisation() {
    return mapUrl._onLoadIDLocalisation
  }

  static updateUrl() {

    // recuperer le path
    let currentURL = new URL(window.location)
    let urlPath = currentURL.pathname
    let urlPathParts = urlPath.split('/')
    urlPathParts.shift() // virer le premier slash

    // construction de la nouvelle URL
    let newUrlPath = []
    newUrlPath[0] = mapUrl._idLocalisation

    let mapSection = newUrlPath.filter(Boolean).join('/')

    // creation de la nouvelle URL en utilisant la base de la précédante et en ajoutant le reste
    let newUrl = '/' + urlPathParts[0] + '/' + mapSection
    history.pushState({}, '', newUrl)
    
  }

  static parseUrl() {

    // recuperer le path
    let currentURL = new URL(window.location)
    let urlPath = currentURL.pathname
    let urlPathParts = urlPath.split('/')
    urlPathParts.shift() // virer le premier slash
    urlPathParts.shift() // virer le nom de la page de carte
    
    mapUrl._idLocalisation = null

    // charger les propiétés static de l’objet avec les sections d’URL
    if (urlPathParts[0] != undefined && urlPathParts[0] != '') mapUrl._idLocalisation = urlPathParts[0]
  }

  static onLoadInit() {

    // parse l’URL courante
    mapUrl.parseUrl()
    mapUrl._onLoadIDLocalisation = mapUrl.getIDLocalisation()
  }

}

// NE PAS APPELER DIRECTEMENT LES PROPRIÉTÉES AVEC UN UNDERSCORE
mapUrl._idLocalisation // contient le slug du POI
mapUrl._onLoadIDLocalisation // contient le slug du POI lors du chargement de la page, cette propriété ne change pas

window.mapUrl = mapUrl