/*
Gére les overlays
Écoute les événements de la carte pour réagir
 */

import $ from 'jquery'
import { destroySlick, slickHomeMap } from './sliders.js'
import { closeOverlayMap } from './functions.js'

export class mapOverlay {

  static init() {
    
    window.addEventListener('marker.panel.show', (e) => {
      this.manageOverlay(e.detail.id)
    })

    window.addEventListener('panel.hide', (e) => {
      this.hideOverlay()
    })
  }

  /**
   * Charger les infos de l'overlay
   */
  static manageOverlay(id) {
    
    // si l'overlay n’est pas affiché, on procede au chargement direct
    // sinon on ferme, puis on charge
    if ( this.isOverlayOpen() ) {
      this.hideOverlay()
      setTimeout( () => this.getLogements(id),400)      
    } else {
      this.getLogements(id)
    }
  }

  /**
   * Ouvrir l'overlay
   */
  static showOverlay() {
    
    closeOverlayMap()
    slickHomeMap()
    document.querySelector('.home-map').classList.add('home-map--is-active') 
  }

  /**
   * Fermer l'overlay
   */
  static hideOverlay() {

    document.querySelector('.home-map').classList.remove('home-map--is-active')
    destroySlick()
  }

  /**
   * État de l'overlay
   */
  static isOverlayOpen() {
    
    return document.querySelector('.home-map').classList.contains('home-map--is-active')
  }

  /**
   * Récupère les infos de l'immeuble
   */
  static getLogements(id) {
    
    $.request('onRefreshImmeuble', {
      data: {
        id: id,
      },
      update: {'mapList::details': '#overlayMap'},
      complete: () => { this.showOverlay() },
      flash: 1,
      handleFlashMessage: function (message, type) {
        $.oc.flashMsg({text: message, class: type})
      }
    })
  }
}

window.mapOverlay = mapOverlay