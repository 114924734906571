/*
|--------------------------------------------------------------------------
| Importation des scripts
|--------------------------------------------------------------------------
|
| Main.js importe tous les fichiers qu'on a de besoin, ça commence par les
| node-modules, puis les fichiers propres au projet.
*/

// Initialisation sur toutes les pages
import $ from 'jquery'
window.jQuery = $
import { initBarba } from './barba'

import '@fancyapps/fancybox/dist/jquery.fancybox.js'
import 'slick-carousel'
// import 'slick-carousel/slick/slick-theme.scss'
// import 'slick-carousel/slick/slick.scss'

import 'jquery-validation'
import 'jquery-validation/dist/additional-methods.js'
import 'jquery-mask-plugin'

import Observer from './../libraries/observer.min.js'
import Parallax from './../libraries/parallax.min.js'
import Scrollfire from './../libraries/scrollfire.min.js'

import { refreshFavorites, resultsLoaded } from './functions/functions.js'

// Importation du style
// import '../scss/style.scss'


// Assignation de constantes
export const OBSERVER = Observer.getInstance()
export const PARALLAX = Parallax.getInstance()
export const SCROLLFIRE = Scrollfire.getInstance()

window.refreshFavorites = refreshFavorites
window.resultsLoaded = resultsLoaded

// Démarrage des scripts en passant par barba
addEventListener('DOMContentLoaded', () => {
  initBarba()
})
