/*

Cette classe gère les markeurs, on utilise les prototypes à cause d’incompatibilités avec Google Map

*/
import $ from 'jquery'
import {mapUrl} from './mapUrl'
import {mapOverlay} from './mapOverlay.js' // récupère l’objet de gestion de l'overlay

// definir une variable pour recevoir la future définition de markeur
export let HTMLMarker

// initMarker est appelé lorsque googlemap est chargé
export function initMarker() {

  // lorsque GoogleMap est chargé on declare un objet et ses prototypes
  HTMLMarker = function (map, lat, lng, id, label) {

    this.lat = lat
    this.lng = lng
    this.pos = new google.maps.LatLng(lat, lng)
    this.id = id
    this.label = label

    HTMLMarker.map = map
    this.associateMap = map // attaché a quelle map ?
    HTMLMarker.markerList.push(this) // Ajout du marqueur à la liste qui est une variable static
    HTMLMarker.showAll() // Ajout des marqueurs sur la page
    this.activeOnLoad = false // est-ce que ce POI doit être activé au chargement ?

    if (mapUrl.getOnLoadIDLocalisation() == id) {
      this.activeOnLoad = true
    }
  }

  HTMLMarker.prototype = new google.maps.OverlayView()

  // affiche le markeur courant
  HTMLMarker.prototype.show = function() {

    if (this.div)
    {
      // On redessine donc le marker pour le changement d'icone.
      this.div.innerHTML = this.renderMarker()
    }

    this.setMap( this.associateMap )
  }

  // cache le markeur courant
  HTMLMarker.prototype.hide = function() {

    this.setMap(null)
  }

  //init your html element here
  HTMLMarker.prototype.onAdd = function () {
    // si pas de latitude et de longitude, on skip l’affichage
    if (this.lat == null || this.lng == null) return

    let div = document.createElement('DIV')
    div.style.position = 'absolute'
    div.className = 'htmlMarker'
    div.style.display = 'none'
    div.innerHTML = '' + this.renderMarker()

    var panes = this.getPanes()
    panes.overlayImage.appendChild(div)
    this.div = div
    $(this.div).fadeIn(150)

    // si ce POI est activé au chargement
    if (this.activeOnLoad) {

      $('a', this.div).trigger('click')
      this.activeOnLoad = false
    }
    
  }

  HTMLMarker.prototype.renderMarker = function () {

    return '<a href="#' + this.id + '" id="' + this.id + '" class="mapPin" data-slug="' +
      '" onClick="HTMLMarker.activate(' + this.id + '); return false;">' +
      '<svg class="marker" width="30" height="30" role="img">' +
      '<use xlink:href="/plugins/blanko/proprietes/assets/images/icons/map.svg#ico-puce"></use>' +
      '</svg>' +
      '<p class="marker__text">' + this.label + '</p>' +
      '</a>'
  }

  HTMLMarker.prototype.draw = function () {

    if (this.lat == null || this.lng == null) return

    var overlayProjection = this.getProjection()
    var position = overlayProjection.fromLatLngToDivPixel(this.pos)
    this.div.style.left = position.x + 'px'
    this.div.style.top = position.y - 30 + 'px'
  }

  // Methode appelée automatiquement si on setMap(null) l’objet, voir doc Google
  HTMLMarker.prototype.onRemove = function () {
    
    if (this.lat == null || this.lng == null) return

    $(this.div).fadeOut(150, () => {
      // si actif, fermer le panel avant le desaffichage
      if ($('a', this.div).hasClass('active')) {
        mapOverlay.hideOverlay()
      }
      this.div.parentNode.removeChild(this.div)
      this.div = null
    })
  }

  // active le POI courant
  HTMLMarker.prototype.activate = function() {

    // envoi un evenement de demande d’ouverture écouté par le panneau
    HTMLMarker.activeEvent(this.id)

    // envoi un evenement de click generique pour le control du submenu
    let event = new CustomEvent('marker.activated', {'detail': { 'id':this.id}})
    window.dispatchEvent(event)
    mapUrl.setIDLocalisation(this.id)
  }

  /********************************
    PROPRIÉTÉS ET METHODES STATICS
   *******************************/
  HTMLMarker.map = null
  HTMLMarker.markerList = []
  HTMLMarker.minZoom = 16 // zomm minimum de la carte

  // active visuellement un marqueur
  HTMLMarker.activate = function (id) {

    let selectorPOI = '#'+id
    
    if ($(selectorPOI).length) {
      if ( !$(selectorPOI).hasClass('active') ) {
        // POI non actif, donc on l'active
        HTMLMarker.desactiveAll()
        $(selectorPOI).parent().css('z-index',+1)
        $(selectorPOI).addClass('active')

        // envoi un evenement de click generique pour le control du submenu
        let event = new CustomEvent('marker.activated', {'detail': { 'id':id}})
        window.dispatchEvent(event)

        // envoi un evenement de demande d’ouverture écouté par le panneau
        HTMLMarker.activeEvent(id)

        // met à jour l’URL        
        if (mapUrl.getIDLocalisation() != id)
          mapUrl.setIDLocalisation(id)
        
        let marker = HTMLMarker.getByID(id)
        if (!HTMLMarker.map.getBounds().contains(marker.pos)) {
          HTMLMarker.fitBounds()
        }
        HTMLMarker.map.panTo(marker.pos)
        HTMLMarker.map.setOptions({
          gestureHandling: "none",
          zoomControl: false,
          zoom: 14,
        })
      } 
      else {
        // ici on clique un POI actif, donc on le ferme.
        HTMLMarker.desactiveAll()
        mapUrl.setIDLocalisation(null)
        mapOverlay.hideOverlay()
        HTMLMarker.map.setOptions({
          gestureHandling: "cooperative",
          zoomControl: true,
        })        
      }
    }
  }

  HTMLMarker.getByID = function(id) {

    for (var i=0; i < HTMLMarker.markerList.length; i++) {
      if (HTMLMarker.markerList[i].id === id) {
        return HTMLMarker.markerList[i]
      }
    }
  }

  HTMLMarker.activeEvent = function(id) {

    // envoi un evenement de demande d’ouverture écouté par le panneau
    let event = new CustomEvent('marker.panel.show', {'detail': { 'id':id }})
    window.dispatchEvent(event)
  }

  HTMLMarker.desactiveAll = function() {

    $('.mapPin.active').removeClass('active')
    $('.htmlMarker').css('z-index', -1) 
  }

  // n’affiche plus les marqueurs sur la map
  HTMLMarker.hideAll = function() {
    HTMLMarker.markerList.map(function(marker) {
      marker.hide()
    })
  }

  // affiche les marqueurs sur la map
  HTMLMarker.showAll = function() {
    HTMLMarker.markerList.map(function(marker) {
      marker.show()
    })
  }

  // Adapte la map pour faire fiter tout les markers dans la vue
  HTMLMarker.fitBounds = function() {

    let bounds = new google.maps.LatLngBounds()

    HTMLMarker.markerList.map(function(marker) {
      // pour chaque marker activé on étend le rectangle de visualisation si le POI dispose de coordonnés ( sinon ça signifie que c’est un KML )
      if ( marker.getMap() !== null && marker.lat != null && marker.lng != null )  { bounds.extend(marker.pos) }
    })

    HTMLMarker.map.fitBounds(bounds)
    let zoom = HTMLMarker.map.getZoom()
    if (zoom > HTMLMarker.minZoom) zoom = HTMLMarker.minZoom
    HTMLMarker.map.setZoom(zoom)
  }

  window.HTMLMarker = HTMLMarker
}