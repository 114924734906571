/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/

import $ from 'jquery'
import { OBSERVER, SCROLLFIRE } from './../main.js'


export const slickBanner = () => {
  $('.js-banner-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-banner-slider-dots',
    speed: 600,
    fade: true,
    pauseOnHover: false,
  })
}


export const homesSlider = () => {
  $('.js-homes-slider').slick({
    dots: false,
    arrows: false,
    speed: 600,
    slidesToShow: 3,
    pauseOnHover: false,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 1439,
      settings: { slidesToShow: 2 }
    }, {
      breakpoint: 1024,
      settings: { variableWidth: true }
    }]
  })

  $('.js-homes-slider-next').on('click', () => {
    $('.js-homes-slider').slick('slickNext')
  })
}


export const slickTown = () => {
  $('.js-town-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-town-slider-dots',
    speed: 600,
    fade: true,
    pauseOnHover: false,
    customPaging: function (slider, i) {
      const title = $(slider.$slides[i]).find('[data-title]').data('title')
      return `<a class="town__dot">${title}</a>`
    },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          autoplaySpeed: 6000,
          customPaging: function (slider, i) {
            return '<a class="town__dot"></a>'
          }
        }
      },
    ]
  })



  $('.js-town-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    let clickedTown = ''
    switch (nextSlide) {
      case 0:
        clickedTown = 'Sainte-Julienne'
        break
      case 1:
        clickedTown = 'Saint-Charles-Borromée'
        break
      case 2:
        clickedTown = 'Saint-Jacques'
        break
      case 3:
        clickedTown = 'Trois-Rivières'
        break
    }

    $('#hiddenTown').val(clickedTown)
  })
}

export const slickHomeMap = () => {
  $('.js-home-map-slider').slick({
    dots: false,
    arrows: false,
    speed: 600,
    fade: true,
    pauseOnHover: false,
  })

  let total = $('.js-home-map-slider .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total < 10 ? '0' + total : total
  $('.js-home-map-total-slide').html(displayedTotal)

  $('.js-home-map-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $('.js-home-map-current-slide').html(slidePosition)
  })

  $('.js-home-map-slider-prev').on('click', () => {
    $('.js-home-map-slider').slick('slickPrev')
  })

  $('.js-home-map-slider-next').on('click', () => {
    $('.js-home-map-slider').slick('slickNext')
  })

  $('.js-home-map-slider-first').on('click', () => {
    $('.js-home-map-slider').slick("slickGoTo", 0);
  })

  $('.js-home-map-slider-last').on('click', () => {
    $('.js-home-map-slider').slick("slickGoTo", $('.js-home-map-slider .slick-slide:not(.slick-cloned)').length - 1);
  })
}


export const slickImages = () => {
  $('.js-images-slider').slick({
    dots: false,
    arrows: false,
    speed: 600,
    slidesToShow: 1,
    pauseOnHover: false,
    swipeToSlide: true,
  })

  $('.js-images-slider-next').on('click', () => {
    $('.js-images-slider').slick('slickNext')
  })

  fixSlickAnim('.js-images-slider')
}


const fixSlickAnim = (slider) => {
  $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let direction, slideCountZeroBased = slick.slideCount - 1

    if (nextSlide == currentSlide)
      direction = 'same'
    else if (slick.currentDirection == 1)
      direction = 'left'
    else
      direction = 'right'

    if (direction == 'right')
      $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')

    if (direction == 'left')
      $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
  })

  $(slider).on('afterChange', () => {
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
  })
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(root = document) {
  $(root).find('.slick-initialized').slick('unslick')
}