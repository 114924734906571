/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

import Overlay from '../../libraries/overlay.js'
import { Accordions } from '../classes/Accordions.js'
import { OBSERVER } from './../main.js'

// Fonction gérant l'overlay Dropdown
export const overlayDropdown = () => {
  const element = document.querySelector('.js-dropdown-container')
  let dropdown = new Overlay({
    name: 'dropdown',
    click: { buttons: { toggle: '.js-toggle-overlay-dropdown', close: 'a[href]' }},
    animations: {
      addTransition: true,
      selector: '.overlay-dropdown',
      styles: [{
        property: 'height',
        value: '100%',
        easing: 'easeInOutQuart'
      }]
    },
    options: { 
      speed: 800,
    }
  })

  dropdown.init()

  const onClick = (e) => {
    if (!element.contains(e.target))
      dropdown.close()
  }

  OBSERVER.add({
    name: 'overlayDropdown',
    event: 'click',
    target: 'window',
    function: onClick
  })
  OBSERVER.on('overlayDropdown')
}


// Fonction gérant l'overlay du menu mobile
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search', close: 'a[href]' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  })
  
  menu.init()

  new Accordions({ containerClass: 'js-accordions-menu', scrollToAccordion: false })
}


// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {
  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.js-close-overlay-share',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  share.init()
}