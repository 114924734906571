/*
|--------------------------------------------------------------------------
| Polyfills
|--------------------------------------------------------------------------
|
| Création des polyfills règlant les problèmes de compatibilité avec IE.
*/

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'


// Apply all polyfill
export const polyfill = () => {
  ieCustomEvent()
  ieObjectFit()
  svg4everybody()
}


// Polyfill for custom event in IE
const ieCustomEvent = () => {
  if ( typeof window.CustomEvent === 'function' ) return false

  const CustomEvent = ( event, params ) => {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    let evt = document.createEvent( 'CustomEvent' )
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail )
    return evt
  }

  CustomEvent.prototype = window.Event.prototype
  window.CustomEvent = CustomEvent
}


// Polyfill pour object-fit sur IE
const ieObjectFit = () => {
  let someImages = document.querySelectorAll('picture img')
  objectFitImages(someImages)
}
