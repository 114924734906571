/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { Accordions } from './classes/Accordions.js'
import { inputsAndTextareaLabel, multiSelect, singleSelect, textareaHeight, submitSearch, resetSearch, removeTag, formContact } from './functions/form.js'
import { backToTopPositionning, clickToScrollToBlock, fancyboxImages, toggleListMap, sideNavStatus, backendKeyBind } from './functions/functions.js'
import { forceBlankOnExterneAndPdfLinks, hundredVH } from './functions/helper.js'
import { overlayDropdown, overlayMenu, overlayShare } from './functions/overlays.js'
import { googleMap, destroyMap } from './functions/map.js'
import { polyfill } from './functions/polyfills.js'
import { destroySlick, homesSlider, slickBanner, slickImages, slickTown } from './functions/sliders.js'
import { PARALLAX, SCROLLFIRE } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'


window.barba = barba

export const initBarba = () => {

  // Dans chaque beforeEnter hook
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
    current.container.remove()
    
    polyfill()
    forceBlankOnExterneAndPdfLinks()
    clickToScrollToBlock({ selector: '.js-back-to-top' })
    backToTopPositionning()
    overlayDropdown()
    overlayMenu()
    backendKeyBind()
  })

  // Dans chaque afterLeave hook
  barba.hooks.afterLeave(() => {
    SCROLLFIRE.destroy()
  })

  barba.hooks.after = (() => {
    fbq('track', '3129419470535195', 'PageView')
  })

  barba.init({

    // Options
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),
    
    // Transitions de page
    transitions: [
      defaultTransition()
    ],

    // Views des pages
    views: [
      
      { // Initialisation des scripts de la page d'accueil
        namespace: 'homeView',
        beforeEnter() {
          slickBanner()
          homesSlider()
          slickTown()
          SCROLLFIRE.init()
          multiSelect()
          hundredVH()
          submitSearch()
          clickToScrollToBlock({ selector: '.js-scrolldown-button', scrollTo: '.js-scrolldown-destination' })
          PARALLAX.init([
            { target: '.js-banner-parallax', parallax: [0, 0, 0, 0.1, 0.4, 1]},
            { target: '.js-featured-parallax', container: '.js-featured-parallax-container', parallax: [0, 0, 0.03, -0.03, 0, 1]},
            { target: '.js-featured-parallax-inner', container: '.js-featured-parallax-container', parallax: [0, 0, -0.1, 0.1, 0, 1]},
            { target: '.js-town-parallax', parallax: [0, 0, -0.07, 0.07, 0, 1]}
          ])
        },
        afterLeave() {
          PARALLAX.destroy()
          destroySlick()
        }
      },

      { // Initialisation des scripts sur la page de résultat de recherche
        namespace: 'searchResultsView',
        beforeEnter() {
          SCROLLFIRE.init()
          multiSelect()
          singleSelect()
          toggleListMap()
          submitSearch()
          resetSearch()
          new Accordions({ scrollToAccordion: false })
          googleMap()
        },
        afterLeave() {
          PARALLAX.destroy()
          destroyMap()
          destroySlick()
        }
      },
      
      { // Initialisation des scripts sur la page détail d'un logement
        namespace: 'homeDetailView',
        beforeEnter() {
          homesSlider()
          SCROLLFIRE.init()
          overlayShare()
          fancyboxImages()
          slickImages()
          new Accordions()
          clickToScrollToBlock({ selector: '.js-description-button', scrollTo: '.description' })
          clickToScrollToBlock({ selector: '.js-caracteristiques-button', scrollTo: '.characteristics' })
          clickToScrollToBlock({ selector: '.js-calculateur-button', scrollTo: '.calculateur' })
          clickToScrollToBlock({ selector: '.js-proximite-button', scrollTo: '.nearest' })
          sideNavStatus()
        },
        afterLeave() {
          destroySlick()
        }
      },
      
      { // Initialisation des scripts sur la page des favoris
        namespace: 'favoritesView',
        beforeEnter() {
          SCROLLFIRE.init()
          multiSelect()
          singleSelect()
          toggleListMap()
          submitSearch()
          new Accordions({ scrollToAccordion: false })
          googleMap()
        },
        afterLeave() {
          destroyMap()
        }
      },

      { // Initialisation des scripts sur la page générique
        namespace: 'genericView',
        beforeEnter() {
          SCROLLFIRE.init()
          new Accordions()
          overlayShare()
          inputsAndTextareaLabel()
          textareaHeight()
          PARALLAX.init([
            { target: '.js-page-image-parallax', calculsBasedOn: 'container', parallax: [0, 0, 0, 1, 0.25, 1]},
            { target: '.js-page-image-parallax-inner', calculsBasedOn: 'container', parallax: [0, 0, 0, 1, 0.25, 1]},
          ])
        }
      },
      
      { // Initialisation des scripts sur la page de la liste des actualités
        namespace: 'newsListView',
        beforeEnter() {
          SCROLLFIRE.init()
          singleSelect()
          overlayShare()
          PARALLAX.init([
            { target: '.js-news-parallax', calculsBasedOn: 'container', parallax: [0, 0, 0, 1, 0, 1]},
            { target: '.js-news-parallax-inner', calculsBasedOn: 'container', parallax: [0, 0, 0, 1, 0, 1]},
          ])
        },
        afterLeave() {
          PARALLAX.destroy()
        }
      },

      { // Initialisation des scripts sur la page de détail d'une actualité
        namespace: 'newsDetailView',
        beforeEnter() {
          SCROLLFIRE.init()
          overlayShare()
          PARALLAX.init([
            { target: '.js-page-image-parallax', calculsBasedOn: 'container', parallax: [0, 0, 0, 1, 0.25, 1]},
            { target: '.js-page-image-parallax-inner', calculsBasedOn: 'container', parallax: [0, 0, 0, 1, 0.25, 1]},
          ])
        },
        afterLeave() {
          PARALLAX.destroy()
        }
      },

      { // Initialisation des scripts sur la page de contact
        namespace: 'contactView',
        beforeEnter() {
          SCROLLFIRE.init()
          new Accordions()
          overlayShare()
          removeTag()
          formContact()
        }
      },

      { // Initialisation des scripts sur la page d'erreur
        namespace: 'errorView',
        beforeEnter() {
          SCROLLFIRE.init()
        }
      }
    ]
  })
}
