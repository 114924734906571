/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

import '@fancyapps/fancybox/dist/jquery.fancybox.js'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER, SCROLLFIRE } from './../main.js'
import { getElementOffset, isVisible } from './helper.js'
import { mapOverlay } from './mapOverlay.js'
import { HTMLMarker } from './mapMarker.js'
import { mapUrl } from './mapUrl'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
    root: options.root
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })
  
  return animation.finished
}


export const backToTopPositionning = () => {
  const element = document.querySelector('.js-back-to-top')
  const footer = document.querySelector('footer')
  const body = document.body
  const docEl = document.documentElement
  
  const onScroll = () => {
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop

    if (scrollTop > 200 && !isVisible(footer))
      element.classList.add('js-back-to-top-active')
    else 
      element.classList.remove('js-back-to-top-active')
  }

  onScroll()

  OBSERVER.add({
    name: 'backToTopPositionning',
    event: 'scroll',
    target: 'document',
    function: onScroll
  })
  OBSERVER.on('backToTopPositionning')
}


export const toggleListMap = () => {
  const mapClass = 'show-map'
  const listClass = 'show-list'
  const page = document.querySelector('.page')

  const toggleToMap = () => {
    if (page.classList.contains(listClass)) {
      page.classList.remove(listClass)
      page.classList.add(mapClass)
      SCROLLFIRE.update()
    }
  }

  const toggleToList = () => {
    if (page.classList.contains(mapClass)) {
      page.classList.remove(mapClass)
      page.classList.add(listClass)
      SCROLLFIRE.update()
    }
  }

  OBSERVER.add({
    name: 'toggleListMap',
    event: 'click',
    target: '.js-list-button',
    function: toggleToList
  })

  OBSERVER.add({
    name: 'toggleListMap',
    event: 'click',
    target: '.js-map-button',
    function: toggleToMap
  })

  OBSERVER.on('toggleListMap')
}

export const fancyboxImages = () => {
  $('.slick-slide:not(.slick-cloned) .images-slider__gallery-link').fancybox({
    selector : '.slick-slide:not(.slick-cloned) .images-slider__gallery-link',
    loop: true,
    animationEffect: 'fade',
    backFocus : false,
  })

  console.log($('.slick-slide:not(.slick-cloned) .images-slider__gallery-link').fancybox())
}

export const refreshFavorites = (data) => {
  if (data.success) {
    let favBtn = $('#fav_' + data.favId)
    var nbFavs = parseInt($('.header__favorites-number').html())

    if (data.isInFavorite) {
      favBtn.children().removeClass('favorite__svg--is-active')
      $('.header__favorites-number').html(nbFavs - 1)
    }
    else {
      favBtn.children().addClass('favorite__svg--is-active')
      $('.header__favorites-number').html(nbFavs + 1)
    }
  }
}

export const resultsLoaded = () => {
  SCROLLFIRE.update()
  scrollToBlock({
    scrollTo: '.section--homes-list',
    offset: -150,
  })
}

export const sideNavStatus = () => {
  const characteristicsOffset = getElementOffset({ element: '.characteristics', root: document }).top - 100,
    calculateurOffset =  getElementOffset({ element: '.calculateur', root: document }).top - 100,
    nearestOffset = getElementOffset({ element: '.nearest', root: document }).top - 100,
    links = document.querySelectorAll('.aside__link'),
    proximite = document.querySelector('.js-proximite-button'),
    calculateur = document.querySelector('.js-calculateur-button'),
    caracteristiques = document.querySelector('.js-caracteristiques-button'),
    description = document.querySelector('.js-description-button')

  const onScroll = () => {
    const currentPosition = window.pageYOffset

    links.forEach(element => element.classList.remove('is-active'))

    if (currentPosition >= nearestOffset)
      proximite.classList.add('is-active')
    else if (currentPosition >= calculateurOffset)
      calculateur.classList.add('is-active')
    else if (currentPosition >= characteristicsOffset)
      caracteristiques.classList.add('is-active')
    else
      description.classList.add('is-active')
  }

  OBSERVER.add({
    name: 'sideNavStatus',
    event: 'scroll',
    target: 'document',
    function: onScroll
  })
  OBSERVER.on('sideNavStatus')
}

export const closeOverlayMap = () => {

  const closeOverlay = () => {
    HTMLMarker.desactiveAll()
    mapUrl.setIDLocalisation(null)
    mapOverlay.hideOverlay()
    HTMLMarker.map.setOptions({
      gestureHandling: "cooperative",
      zoomControl: true,
    })  
  }

  OBSERVER.add({
    name: 'closeOverlayMap',
    event: 'click',
    target: '.home-map__close',
    function: closeOverlay
  })

  OBSERVER.on('closeOverlayMap')
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}
